import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HubioLoginCallbackComponent, HubioLoginComponent, RouteGuard, PendingApprovalPage } from '@hubio/hubio-login';
import { ErrorNotFoundPage, ErrorServerUnavailablePage } from '@hubio/hubio-common-pages';

const pageTitle = 'Hubio User Management';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: HubioLoginComponent
  },
  {
    path: 'login/callback',
    component: HubioLoginCallbackComponent
  },
  {
    path: 'pending-approval',
    component: PendingApprovalPage,
    data: {
      pageTitle
    }
  },
  {
    path: 'error',
    children: [
      {
        path: '503',
        component: ErrorServerUnavailablePage,
        data: {
          pageTitle
        }
      }
    ]
  },
  {
    path: 'users',
    loadChildren: () => import('@modules/users/user.module').then(m => m.UserModule),
    canActivate: [RouteGuard],
    data: {
      allowedGroups: [
        'auth-role-admin'
      ]
    }
  },
  {
    path: 'groups',
    loadChildren: () => import('@modules/groups/group.module').then(m => m.GroupModule),
    canActivate: [RouteGuard],
    data: {
      allowedGroups: [
        'auth-role-admin'
      ]
    }
  },
  {
    path: 'pending',
    loadChildren: () => import('@modules/pending-signups/pending-signups.module').then(m => m.PendingSignupsModule),
    canActivate: [RouteGuard],
    data: {
      allowedGroups: [
        'auth-role-admin'
      ]
    }
  },
  {
    path: '**',
    component: ErrorNotFoundPage,
    data: {
      pageTitle
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
