// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  authURL: $ENV.authURL || 'http://localhost:3090/api/v1',
  backendURL: $ENV.backendURL || 'http://localhost:3050/api/v1',
  LOGIN_URL: $ENV.LOGIN_URL || "https://okta-poc-hubio.auth.ca-central-1.amazoncognito.com/oauth2/authorize?client_id=24t3iprb685qrqmj9i6mirqbe4&response_type=code&scope=email+openid+phone+profile&redirect_uri=http%3A%2F%2Flocalhost%3A4200%2Flogin%2Fcallback",
  LOGOUT_URL: $ENV.LOGOUT_URL || "https://okta-poc-hubio.auth.ca-central-1.amazoncognito.com/logout?client_id=24t3iprb685qrqmj9i6mirqbe4&response_type=code&scope=email+openid+phone+profile&redirect_uri=http%3A%2F%2Flocalhost%3A4200%2Flogin%2Fcallback",
  HOMEPAGE_ROUTE: $ENV.HOMEPAGE_ROUTE || "/users/",
  TENANT: $ENV.TENANT || "authentication",
  REDIRECT_URL: $ENV.REDIRECT_URL,
  ENVIRONMENT: $ENV.ENVIRONMENT || "development"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
