import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  visibility: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() { }

  public show() {
    this.visibility.next(true);
  }

  public hide() {
    this.visibility.next(false);
  }
}
