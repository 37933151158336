import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtInterceptor } from './core/interceptors/jwt.token.interceptor';
import { ErrorInterceptor } from './core/interceptors/http.interceptor';
import { MatButtonModule, MatDialogModule } from '@angular/material';
import { BasicDialogComponent } from '@core/components/basic-dialog/basic-dialog.component';
import { HubioLoginModule, JwtInterceptor as JwtExpirationInterceptor } from '@hubio/hubio-login';
import { HubioCommonPagesModule } from '@hubio/hubio-common-pages';
import { environment } from '@environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    BasicDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatButtonModule,
    HttpClientModule,
    HubioLoginModule.forRoot(environment),
    HubioCommonPagesModule.forRoot(environment)
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtExpirationInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    BasicDialogComponent
  ]
})
export class AppModule { }
