import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-basic-dialog',
  templateUrl: './basic-dialog.component.html',
  styleUrls: ['./basic-dialog.component.scss']
})
export class BasicDialogComponent {
  message: string;
  title: string;
  buttons: {
    label: string;
    action: string;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<BasicDialogComponent>
  ) {
    this.message = data.message;
    this.title = data.title;
    this.buttons = data.buttons || [];
  }
}
