import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { SpinnerService } from '@core/services/spinner.service';
import { HubioLoginService } from '@hubio/hubio-login';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  requestCount: number = 0;

  constructor(
    private SpinnerService: SpinnerService,
    private router: Router,
    private auth: HubioLoginService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.showSpinner();

    return next.handle(request).pipe(
      // timeout(environment.httpRequestDuration),
      catchError(err => {
        if (err.name === 'TimeoutError') {
          console.error(err);
          return throwError(err);
        } else if (err.status === 401 || err.status === 403) {
          this.auth.logout();
          return throwError(err);
        } else if (err.status === 503) {
          this.router.navigate(['/error/503']);
        } else {
          console.error(err);
          return throwError(err);
        }
      }),
      finalize(
        () => {
          this.stopSpinner();
        }
      )
    )
  }

  showSpinner(): void {
    this.requestCount++;
    this.SpinnerService.show();
  }

  stopSpinner(): void {
    this.requestCount--;
    if (this.requestCount === 0) {
      this.SpinnerService.hide();
    }
  }
}